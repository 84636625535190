<template>
    <div class="dispObjects">

        <div class="dispObjects__title">Доступные объекты</div>
        <div class="dispObjects__list">

            <div class="dispObjects__group">
                <div class="dispObjects__item" v-for="item in getObjects" :key="item.id">
                    <div class="dispObjects__itemWrapper" v-if="item.online.value == 3">
                        <span class="dispObjects__itemStatus" :class="item.online.marker"></span>
                        <router-link :to="`/car/${item.id}`" class="dispObjects__itemLink">
                            {{ item.name }}
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="dispObjects__group">
                <div class="dispObjects__item" v-for="item in getObjects" :key="item.id">
                    <div class="dispObjects__itemWrapper" v-if="item.online.value == 2">
                        <span class="dispObjects__itemStatus" :class="item.online.marker"></span>
                        <router-link :to="`/car/${item.id}`" class="dispObjects__itemLink">
                            {{ item.name }}
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="dispObjects__group">
                <div class="dispObjects__item" v-for="item in getObjects" :key="item.id">
                    <div class="dispObjects__itemWrapper" v-if="item.online.value == 1">
                        <span class="dispObjects__itemStatus" :class="item.online.marker"></span>
                        <router-link :to="`/car/${item.id}`" class="dispObjects__itemLink">
                            {{ item.name }}
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="dispObjects__group">
                <div class="dispObjects__item" v-for="item in getObjects" :key="item.id">
                    <div class="dispObjects__itemWrapper" v-if="item.online.value == 0">
                        <span class="dispObjects__itemStatus" :class="item.online.marker"></span>
                        <router-link :to="`/car/${item.id}`" class="dispObjects__itemLink">
                            {{ item.name }}
                        </router-link>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Main',
    computed: {
        ...mapGetters(['getObjects', 'getPoints'])
    },
    methods: {
        updateOnline() {
            let objects = this.getObjects,
                points = this.getPoints
            if(objects.length && points.length) {
                for(let obj of objects) {
                    for(let point of points) {
                        if(point.id == obj.id) {
                            obj.calcOnline(point)
                        }
                    }
                }
            }
        }
    },
    mounted() {
        const _this = this
        setInterval( function() {
            _this.updateOnline()
        }, 60 * 1000 )
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/views/main';
</style>

